// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "F_fT";
export var businessesSectionMvp = "F_fR";
export var clientsMvpSection = "F_f3";
export var devApproachSectionMvp = "F_fV";
export var domainExpertiseMvpSection = "F_fQ";
export var launchProductSectionMvp = "F_fW";
export var launchProductSectionMvp__testimonial = "F_fX";
export var ourAchievementsMvp = "F_f2";
export var servicesCardBorderIconMvpSection = "F_fP";
export var servicesDevExpertiseMvp = "F_fY";
export var servicesProcessMvpSection = "F_fS";
export var successStoriesMvpSection = "F_f0";
export var technologiesSectionMvp = "F_fZ";
export var whyChooseMvpSection = "F_f1";